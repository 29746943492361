// scrollToTop.js

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

function toggleScrollToTopButton() {
  const scrollToTopBtn = document.getElementById('scrollToTopBtn');
  if (window.pageYOffset > 300) {
    scrollToTopBtn.classList.remove('opacity-0', 'invisible', 'translate-y-10');
    scrollToTopBtn.classList.add('opacity-100', 'translate-y-0');
  } else {
    scrollToTopBtn.classList.remove('opacity-100', 'translate-y-0');
    scrollToTopBtn.classList.add('opacity-0', 'translate-y-10');
    // Add a delay before making the button invisible
    setTimeout(() => {
      if (window.pageYOffset <= 300) {
        scrollToTopBtn.classList.add('invisible');
      }
    }, 500); // Match this delay with the transition duration
  }
}

// Execute scrollToTop when the page loads
document.addEventListener('DOMContentLoaded', () => {
  scrollToTop();
  
  const scrollToTopBtn = document.getElementById('scrollToTopBtn');
  scrollToTopBtn.addEventListener('click', scrollToTop);
  
  window.addEventListener('scroll', toggleScrollToTopButton);

  // Initial check for button visibility
  toggleScrollToTopButton();
});

// Execute scrollToTop when the route changes (for single-page applications)
if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual';
}

export { scrollToTop };